$breakpoints: (
  'phone': 600px,
  'tablet-portrait': 770px,
  'tablet-landscape': 1030px,
  'desktop': 1200px,
) !default;

@mixin respond-to($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    @media only screen and (max-width: map-get($breakpoints, $breakpoint)) {
      @content;
    }
  }
}
