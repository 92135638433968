@import '../../../styles/colours';
@import '../../../styles/fonts';
@import '../../../styles/responsive';
@import '../../../styles/sizing';

.profile {
  align-items: center;
  background-color: $white;
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-top: 62px;
  margin-right: $spacing * 2;
  padding: 0 $spacing * 2 $spacing * 2;

  @include respond-to('tablet-portrait') {
    flex: 0 1 auto;
    justify-content: center;
    margin: 0 0 $spacing;
    min-height: 124 + ($spacing * 2);
    padding: 0 $spacing * 2 0 124 + ($spacing * 2);
    position: relative;
    width: 100%;
  }

  @include respond-to('phone') {
    min-height: unset;
    padding-left: 62 + ($spacing * 2);
  }

  a {
    border: 2px solid $white;
    border-radius: 62px;
    height: 124px;
    margin-top: -62px;
    overflow: hidden;
    transition: 0.25s border-color ease-in-out;
    width: 124px;

    @include respond-to('tablet-portrait') {
      bottom: 0;
      left: $spacing;
      margin: auto 0;
      position: absolute;
      top: 0;
    }

    @include respond-to('phone') {
      border-radius: 31px;
      height: 62px;
      width: 62px;
    }

    &:hover {
      border-color: $secondary;
    }
  }

  img {
    height: 124px;
    width: auto;

    @include respond-to('phone') {
      height: 62px;
    }
  }
}

.name {
  color: $primary;
  font-size: 1.4em;
  font-weight: 600;
  margin-top: $spacing;
}

.location {
  margin-top: $spacing * 0.5;
}

.joined {
  color: $secondary;
  font-size: 0.9em;
  font-weight: 600;
  margin: $spacing 0;
}
