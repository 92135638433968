html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
font,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td {
  background: transparent;
  border: 0;
  margin: 0;
  padding: 0;
  vertical-align: baseline;
}

body {
  line-height: 1;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
}

ol,
ul {
  list-style: none;
}

blockquote {
  quotes: none;
}

del {
  text-decoration: line-through;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

a {
  img {
    border: 0;
  }
}

input,
textarea,
select {
  appearance: none;
  border-radius: 0;
}

button {
  background: none;
  border: 0;
  cursor: pointer;
  margin: 0;
  padding: 0;
}
