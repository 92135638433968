@import '../../styles/colours';
@import '../../styles/responsive';
@import '../../styles/sizing';

.container {
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: center;
}

.content {
  background-color: $white;
  border-radius: 5px;
  display: flex;
  flex: 1;
  flex-direction: column;
  margin: $spacing * 2;
  max-width: 600px;
  padding: $spacing * 2;
  text-align: center;

  p {
    margin-bottom: $spacing;
    margin-top: $spacing;
  }
}

.title {
  align-items: center;
  align-content: center;
  color: $primary;
  display: flex;
  font-weight: 600;
  justify-content: center;
  margin-bottom: $spacing;
  margin-top: $spacing;

  @include respond-to('phone') {
    font-size: 1.6em;
  }
}

.icon {
  margin-right: $spacing * 2;
}

.buttons {
  justify-content: space-around;
  display: flex;
  margin-top: $spacing;
}

.button-image {
  width: 191px;
}

.button {
  flex: 0;
}

.google {
  .google-normal {
    display: block;
  }

  .google-pressed {
    display: none;
  }

  &:hover {
    .google-normal {
      display: none;
    }

    .google-pressed {
      display: block;
    }
  }
}
