@import '../../../styles/colours';
@import '../../../styles/fonts';
@import '../../../styles/responsive';
@import '../../../styles/sizing';

.container {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
}

.loading {
  opacity: 0.25;
}

.month {
  background: $white;
  border-right: 1px solid $background;
  display: flex;
  flex: 0 0 (100 / 6) * 1%;
  flex-direction: column;
  margin-bottom: 10px;
  text-align: center;

  @include respond-to('phone') {
    border-bottom: 1px solid $background;
    flex: 0 0 (100 / 3) * 1%;
    margin-bottom: 0;
  }

  h4 {
    color: $primary;
    font-weight: 600;
    margin: 0;
    padding: $spacing * 0.5;

    @include respond-to('tablet-portrait') {
      padding: $spacing * 0.5 $spacing * 0.25;
    }
  }

  p {
    align-items: center;
    border-top: 1px solid $background;
    display: flex;
    padding: $spacing * 0.5;

    @include respond-to('tablet-portrait') {
      padding: $spacing * 0.5 $spacing * 0.25;
    }

    span {
      text-align: center;

      &.distance {
        flex: 1;
        font-family: $fixed;
      }

      &.unit {
        flex: 0 0 32px;
        font-size: 0.9em;
      }
    }

    .icon {
      align-self: center;
      align-items: center;
      display: flex;
      flex: 0 0 32px;
      justify-content: center;
    }
  }

  &.current {
    h4 {
      color: $secondary;
    }
  }

  &.future {
    > * {
      color: lighten($text, 35);
    }
  }
}

.totals {
  background: $white;
  display: flex;
  flex: 0 0 100%;

  @include respond-to('phone') {
    flex-direction: column;
    margin-top: $spacing;
  }

  > * {
    display: flex;
    flex: 1;
    padding: $spacing;
  }

  h3 {
    align-items: center;
    color: $primary;
    font-size: 1.4em;
    font-weight: 600;
  }

  p {
    align-items: center;
    border-left: 1px solid $background;

    @include respond-to('phone') {
      border-left: 0;
      border-top: 1px solid $background;
    }

    span {
      text-align: center;

      &.distance {
        flex: 1;
        font-family: $fixed;
        font-size: 1.2em;
        font-weight: 600;
      }

      &.unit {
        flex: 0 0 32px;
      }
    }

    .icon {
      align-self: center;
      align-items: center;
      color: lighten($text, 25);
      display: flex;
      flex: 0 0 32px;
      justify-content: center;
    }
  }
}

.controls {
  display: flex;
  flex: 0 0 100%;
  justify-content: flex-end;
  margin-top: 10px;
}
