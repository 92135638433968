@import '../../styles/colours';
@import '../../styles/fonts';
@import '../../styles/responsive';
@import '../../styles/sizing';

.container {
  align-items: flex-start;
  display: flex;
  flex: 1;
  justify-content: flex-start;
  padding: 0 $spacing * 2;

  @include respond-to('tablet-portrait') {
    flex-direction: column;
    padding: 0 $spacing;
  }
}

.report {
  display: flex;
  flex-direction: column;
  flex: 4;

  @include respond-to('phone') {
    flex: 1;
  }
}

.nav {
  align-items: center;
  background-color: $white;
  display: flex;
  justify-content: center;

  button {
    flex: 0 0 50px;
    height: 50px;

    &:focus {
      outline: 0;
    }

    &:first-child {
      border-right: 1px solid $background;
    }

    &:last-child {
      border-left: 1px solid $background;
    }
  }

  h3 {
    flex: 1;
    font-family: $fixed;
    font-weight: 600;
    text-align: center;
  }
}
