@import '../../../styles/colours';
@import '../../../styles/fonts';
@import '../../../styles/sizing';

.container {
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: center;

  p {
    flex: 0 1;
    font-size: 0.8em;
  }

  > span {
    flex: 1;
  }
}

.switch {
  background-color: white;
  border-radius: 15px;
  display: flex;
  flex: 0 0 50px;
  height: 24px;
  margin: 0 12px;
  padding: 2px;
  transition: padding-left 0.25s ease-in-out;

  &:focus {
    outline: 0;
  }

  &.selected {
    padding-left: 28px;
  }

  span {
    background-color: $secondary;
    border-radius: 10px;
    flex: 0 0 20px;
    height: 20px;
  }
}
