@import '../../styles/colours';
@import '../../styles/sizing';

.container {
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: center;
}

.content {
  background-color: $white;
  border-radius: 5px;
  display: flex;
  flex: 1 1;
  flex-direction: column;
  margin: $spacing * 2;
  max-width: 600px;
  padding: $spacing * 2;
  text-align: center;

  p {
    margin-bottom: $spacing;
    margin-top: $spacing;

    &.message {
      color: $error;
      font-weight: 600;
    }
  }
}

.title {
  align-content: center;
  color: $primary;
  display: flex;
  font-weight: 600;
  justify-content: center;
  margin-bottom: $spacing;
  margin-top: $spacing;

  span {
    color: $secondary;
  }
}

.buttons {
  justify-content: space-around;
  display: flex;
  margin-top: $spacing;
}

.button {
  flex: 0;

  &.unavailable {
    opacity: 0.2;
    pointer-events: none;
  }

  .normal {
    display: block;
  }

  .pressed {
    display: none;
  }

  &:hover {
    .normal {
      display: none;
    }

    .pressed {
      display: block;
    }
  }
}
