$white: #fff;
$purple: #3a277b;
$orange: #f45d01;
$light-grey: #f7f7fa;
$dark-grey: #6f6f6f;
$red: #cc0000;

$text: $dark-grey;
$background: $light-grey;
$primary: $purple;
$secondary: $orange;
$error: $red;
