@import '../../styles/colours';
@import '../../styles/fonts';
@import '../../styles/responsive';
@import '../../styles/sizing';

.container {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.header {
  align-items: center;
  background-color: $white;
  display: flex;
  left: 0;
  padding-left: $spacing;
  padding-right: $spacing;
  position: fixed;
  right: 0;
  top: 0;

  @include respond-to('tablet-portrait') {
    margin-bottom: $spacing;
    position: static;
  }

  @include respond-to('phone') {
    align-items: flex-start;
    flex-direction: column;
  }
}

.title {
  align-content: center;
  color: $primary;
  display: flex;
  font-size: 1.4em;
  font-weight: 600;
  justify-content: center;
  margin-bottom: $spacing;
  margin-top: $spacing;
}

.icon {
  margin-right: $spacing;
}

.nav {
  display: flex;
  flex: 1;

  ul {
    display: flex;
    flex: 1;
    justify-content: flex-end;
  }

  li {
    margin-left: $spacing * 2;

    @include respond-to('phone') {
      margin: $spacing $spacing * 2 $spacing 0;
    }
  }
}

.nav-link {
  color: $text;
  font-family: $fixed;
  font-size: 0.8em;
  font-weight: 600;
  text-decoration: none;
  text-transform: uppercase;

  &.active {
    color: $secondary;
  }
}
