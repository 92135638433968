@import './styles/colours';
@import './styles/fonts';
@import './styles/responsive';
@import './styles/sizing';

@import './styles/reset';

* {
  box-sizing: border-box;
  line-height: 1.5em;
}

html,
body {
  height: auto;
  margin: 0;
  min-height: 100%;
  width: 100%;
}

body {
  background-color: $background;
  color: $text;
  display: flex;
  flex-direction: column;
  font-family: $body;
  font-size: 100%;
  height: auto;
  min-height: 100%;
  padding: 80px 0;
  position: absolute;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  @include respond-to('tablet-portrait') {
    font-size: 85%;
    padding: 0;
  }
}

code {
  font-family: $fixed;
}

#root {
  display: flex;
  flex: 1;
}
